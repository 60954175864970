<template>
  <app-page :toolbar="toolbar" :list="list" @loaded="onLoaded" />
</template>

<script>
import mixins from './mixins';

export default {
  name: 'Temp',
  mixins
};
</script>
